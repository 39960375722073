import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import Image from '@components/SanityImage';
import WebinarSpeaker from '@components/webinar/WebinarSpeaker';

import * as styles from './WebinarCover.module.css';

const WebinarCover = ({
  title,
  cover,
  speakers = [],
  className,
  theme = 'light',
  variant = 'full',
}) => {
  const showTitle = variant === 'full';
  const showSpeakers =
    speakers.length > 0 && (variant === 'full' || variant === 'reduced');
  const showFullSpeakers = showSpeakers;

  return (
    <article
      className={cn(styles.root, className)}
      data-theme={theme}
      data-variant={variant}
    >
      <Image className={styles.cover} asset={cover} alt={title} fill />

      <header className={styles.details}>
        {showTitle && <h2 className={styles.title}>{title}</h2>}
        {showSpeakers && (
          <div className={styles.speakers}>
            {speakers.map((speaker) => (
              <React.Fragment key={speaker.name}>
                {showFullSpeakers && speaker.image && (
                  <WebinarSpeaker
                    name={speaker.name}
                    title={speaker.title}
                    company={speaker.company}
                    image={speaker.image}
                    size="small"
                    font="mono"
                  />
                )}
              </React.Fragment>
            ))}
          </div>
        )}
      </header>
    </article>
  );
};

WebinarCover.propTypes = {
  title: PropTypes.string.isRequired,
  cover: PropTypes.object.isRequired,
  speakers: PropTypes.array,
  theme: PropTypes.oneOf(['light', 'dark']),
  variant: PropTypes.oneOf(['full', 'reduced', 'empty']),
};

export default WebinarCover;
