import React from 'react';
import PropTypes from 'prop-types';

import * as styles from './WebinarSpeaker.module.css';
import Image from '@components/SanityImage';

const WebinarSpeaker = ({
  company,
  image,
  name,
  title,
  size = 'default',
  font = 'body',
}) => {
  return (
    <figure className={styles.root} data-size={size} data-font={font}>
      <div className={styles.imageWrapper}>
        {image && (
          <Image asset={image} alt={name} className={styles.image} fill />
        )}
      </div>
      <figcaption className={styles.details}>
        <div className={styles.name}>{name}</div>
        <div className={styles.subline}>
          {title && <span>{title}</span>}
          {company && <span>{company}</span>}
        </div>
      </figcaption>
    </figure>
  );
};

WebinarSpeaker.propTypes = {
  name: PropTypes.string.isRequired,
  title: PropTypes.string,
  company: PropTypes.string,
  image: PropTypes.object,
  font: PropTypes.oneOf(['body', 'mono']),
  size: PropTypes.oneOf(['default', 'small']),
};

export default WebinarSpeaker;
