import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import * as styles from './Stack.module.css';

/* eslint-disable-next-line */
const BaseStack = (direction) => {
  const RootStack = React.forwardRef(
    ({ as = 'div', children, className, space, ...rest }, ref) => {
      const Tag = as;
      return (
        <Tag
          ref={ref}
          className={cn(direction, className, styles.root)}
          data-space={space}
          {...rest}
        >
          {children}
        </Tag>
      );
    },
  );

  RootStack.displayName = 'RootStack';
  return RootStack;
};

export const HorizontalStack = BaseStack(styles.horizontal);
HorizontalStack.propTypes = {
  as: PropTypes.oneOf([
    'div',
    'p',
    'span',
    'ul',
    'ol',
    'article',
    'header',
    'footer',
  ]),
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  space: PropTypes.oneOf([
    '3xs',
    '2xs',
    'xs',
    's',
    'm',
    'l',
    'xl',
    '2xl',
    '3xl',
    '4xl',
  ]),
};

export const VerticalStack = BaseStack(styles.vertical);
VerticalStack.propTypes = {
  as: PropTypes.oneOf([
    'div',
    'p',
    'span',
    'ul',
    'ol',
    'article',
    'header',
    'footer',
  ]),
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  space: PropTypes.oneOf([
    '3xs',
    '2xs',
    'xs',
    's',
    'm',
    'l',
    'xl',
    '2xl',
    '3xl',
    '4xl',
  ]),
};
