import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import textStyles from '@styles/textStyles.module.css';
import styles from './WebinarPageSection.module.css';

const WebinarPageSection = ({
  as = 'section',
  children,
  className,
  title,
  skipBorder,
}) => {
  const Comp = as;

  return (
    <Comp
      className={cn(styles.root, className)}
      data-skip-border={skipBorder ? 'true' : null}
    >
      <header className={styles.header}>
        <h2 className={cn(styles.title, textStyles.eyebrow)}>{title}</h2>
      </header>
      <main className={styles.main}>{children}</main>
    </Comp>
  );
};

WebinarPageSection.propTypes = {
  as: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
  title: PropTypes.string,
  skipBorder: PropTypes.bool,
};

export default WebinarPageSection;
