import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import { readableDate } from '@util/date';
import { formatWebinarTime } from '@util/webinars';

import PlayIcon from '@icons/Play.svg';

import Button from '@components/Button';
import WebinarCover from '@components/webinar/WebinarCover';

import styles from './WebinarTeaser.module.css';
import textStyles from '@styles/textStyles.module.css';
import { TickListItem } from '@components/TickList';
import WebinarPageSection from '../WebinarPageSection';
import { VerticalStack } from '@components/Stack';
import WebinarSpeaker from '../WebinarSpeaker';

const WebinarTeaser = ({
  callToAction = 'Watch now',
  cover,
  coverTheme = 'dark',
  theme,
  title,
  date,
  duration,
  description,
  focusItems,
  speakers = [],
  titleAs = 'h3',
  variant = 'secondary',
  withPlayIcon = false,
  withDetails = false,
}) => {
  const TitleComp = titleAs;
  const isPrimary = variant === 'primary';

  return (
    <article
      className={styles.root}
      data-variant={variant}
      data-details={withDetails ? 'true' : null}
    >
      <figure className={styles.coverWrapper} data-theme={coverTheme}>
        {withPlayIcon && (
          <span aria-hidden="true" className={cn(styles.playIcon)}>
            <PlayIcon />
          </span>
        )}
        <WebinarCover
          className={styles.cover}
          cover={cover}
          title={title}
          variant="empty"
          theme={theme}
        />
      </figure>
      <div className={styles.right}>
        <div className={styles.meta}>
          <div className={styles.metaList}>
            <div className={cn(styles.metaListItem, styles.date)}>
              {readableDate(date.utc)}
            </div>
            <div className={cn(styles.metaListItem, styles.time)}>
              {formatWebinarTime({ date })}
            </div>
            {!withPlayIcon && (
              <div className={styles.metaDuration}>{duration}&thinsp;mins</div>
            )}
          </div>
        </div>
        <TitleComp
          className={cn(styles.title, {
            [textStyles.headlineL]: isPrimary,
            [textStyles.headlineS]:
              variant === 'tertiary' || variant === 'secondary',
          })}
        >
          {title}
        </TitleComp>

        {isPrimary && <div className={styles.text}>{description}</div>}

        {withDetails && (
          <VerticalStack space="m" className={styles.details}>
            <WebinarPageSection title="Things you’ll learn">
              <ul className={styles.tickList}>
                {focusItems.map((item) => (
                  <TickListItem key={item} size="large">
                    {item}
                  </TickListItem>
                ))}
              </ul>
            </WebinarPageSection>

            <WebinarPageSection title="Speakers">
              <div className={styles.speakers}>
                {speakers.map((speaker) => (
                  <WebinarSpeaker
                    key={speaker.name}
                    name={speaker.name}
                    company={speaker.company}
                    title={speaker.title}
                    image={speaker.image}
                  />
                ))}
              </div>
            </WebinarPageSection>
          </VerticalStack>
        )}

        {isPrimary && callToAction && (
          <Button aria-hidden className={styles.action} variant="secondary">
            {callToAction}
          </Button>
        )}
      </div>
    </article>
  );
};

WebinarTeaser.propTypes = {
  callToAction: PropTypes.string,
  cover: PropTypes.object.isRequired,
  coverTheme: PropTypes.oneOf(['dark', 'light']),
  title: PropTypes.string.isRequired,
  date: PropTypes.object.isRequired,
  duration: PropTypes.number.isRequired,
  variant: PropTypes.oneOf(['primary', 'secondary', 'tertiary']),
  speakers: PropTypes.array,
};

export default WebinarTeaser;
